/* Container for the entire keyboard section */
.keyboard-container {
    text-align: center;
    max-width: 1000px;  /* Adjusted to make sure the entire keyboard fits */
    margin: 0 auto;
    padding: 20px;
    border: 2px solid #333;
    border-radius: 10px;
    background-color: #f9f9f9;
}

/* Title styling */
.keyboard-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
}

/* Paragraph note styling */
.keyboard-container p {
    font-size: 14px;
    margin-bottom: 10px;
    color: #666;
}

/* Main keyboard area */
.keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 10px;
}

/* Each row of keys */
.key-row {
    display: flex;
    justify-content: center;
    gap: 5px;
}

/* Individual key styling */
.key {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;  /* Adjusted to ensure all keys fit inside the container */
    height: 60px; /* Adjusted to ensure all keys fit inside the container */
    background-color: #ddd;
    border: 1px solid #aaa;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    user-select: none;
    box-sizing: border-box;
    word-wrap: break-word;  /* Ensures long text breaks to fit inside the key */
    overflow-wrap: break-word; /* Ensures long text breaks to fit inside the key */
    padding: 5px; /* Added padding to give space for wrapping text */
}

/* Styling for pressed keys */
.key.pressed {
    background-color: #76c7c0;
    border-color: #58a9a2;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* Typing test section */
.typing-test {
    margin-top: 20px;
    text-align: center;
}

.typing-test textarea {
    width: 100%;
    max-width: 600px;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none; /* Prevent resizing */
}

/* History section for showing pressed keys */
.key-history {
    margin-top: 20px;
    text-align: center; /* Center align the history */
}

/* History title */
.key-history h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

/* History list of keys */
.key-history ul {
    list-style-type: none;
    padding: 0;
    display: flex; /* Change to flex to display items in a row */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    justify-content: center; /* Center align the items */
    gap: 5px; /* Space between items */
}

.key-history ul li {
    font-size: 16px;
    margin: 0; /* Remove vertical margins */
    padding: 5px; /* Add padding for spacing */
    border: 1px solid #ccc; /* Optional: border around each key */
    border-radius: 5px; /* Optional: rounded corners */
    background-color: #f0f0f0; /* Optional: background color for better visibility */
}

/* Specific styling for special keys */
.key[data-key="Space"] {
    width: 300px;  /* Spacebar width adjusted */
}

.key[data-key="Backspace"],
.key[data-key="Enter"],
.key[data-key="ShiftLeft"],
.key[data-key="ShiftRight"],
.key[data-key="CapsLock"],
.key[data-key="Tab"] {
    width: 80px; /* Adjusted for longer keys */
}

/* Ensuring smaller keys don't overflow */
.key[data-key="BracketLeft"],
.key[data-key="BracketRight"],
.key[data-key="Backslash"] {
    width: 55px;
}

@media screen and (max-width: 768px) {
    .key {
        width: 50px;  /* Adjust for smaller screens */
        height: 50px;
        font-size: 12px;
    }

    .key[data-key="Space"] {
        width: 200px;  /* Adjust for smaller screens */
    }

    .keyboard-container {
        padding: 10px;
    }
}

@media screen and (max-width: 480px) {
    .key {
        width: 45px;  /* Further adjust for mobile screens */
        height: 45px;
        font-size: 10px;
    }

    .key[data-key="Space"] {
        width: 150px;  /* Adjust for smaller screens */
    }

    .keyboard-container {
        padding: 5px;
    }
}
