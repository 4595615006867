/* Global Styles */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
  height: 100%;
  font-family: Arial, sans-serif;
  zoom: 90%; /* Restrict zoom to 90% */
  cursor: default; /* Default cursor for the entire site */
}

/* Ensure scrolling if content overflows */
body {
  overflow-y: auto;
}

/* Ensure elements in the site use default cursor */
* {
  cursor: default !important; /* Use !important to override all other cursor settings */
}

/* Specific elements that should show typing cursor */
input, textarea, select, .editable {
  cursor: text; /* Typing cursor for text input elements */
}
